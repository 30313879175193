import React from 'react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

type Props = { questionId: string; answer: string }

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[800]}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
const FeedbackDataListItem = ({ questionId, answer }: Props) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" spacing={4} className={classes.root}>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id={questionId} />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          style={{ lineHeight: 1 }}
          dangerouslySetInnerHTML={{ __html: answer === '' ? 'null' : answer }}
        />
      </Grid>
    </Grid>
  )
}
export default FeedbackDataListItem
