import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'

import {
  getFeedbacks,
} from '@services/feedbackuserservice'
import { IFeedbacksProps } from '@services/feedbackuserservice'
import FeedbackCard from '@objects/cards/feedbacks'

export default function FeedbacksPage() {
  const intl = useIntl()
  const [feedbacks, setFeedbacks] = useState<Array<IFeedbacksProps>>()
  const [loading, setLoading] = useState(false)

  function onActionClick() {
      updateFeedbacksList()
  }

  function updateFeedbacksList() {
    setLoading(true)
    getFeedbacks().then((request: IFeedbacksProps[]) => {
      setFeedbacks(request)
      setLoading(false)
    })
  }

  function renderCards() {
    if (!feedbacks?.length) return

    return feedbacks.map((feedback) => (
      <FeedbackCard
        key={feedback.id}
        feedback={feedback}
        onActionClick={onActionClick}
      />
    ))
  }

    useEffect(() => {
    updateFeedbacksList()
  }, [])

  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      <LoadingSkeleton loading={loading} type="card" number={4}>
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}
