import React from 'react'
import PropTypes from 'prop-types'
import FeedbacksPage from '@components/feedbacks'

export const frontmatter = {
  title: 'Feedbacks',
}

function Feedbacks() {
  return <FeedbacksPage />
}

Feedbacks.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Feedbacks
